.login-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(6 px);
    padding: 0 10px;
}

