:host {
    --bg-clr: green
}


#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: white;
}

.site-layout .site-layout-background {
    background: #fff;
}

.ant-layout-sider {
    background-color: #fff !important;
    border-right: 5px solid rgb(116, 113, 113);
}

.ant-menu-dark .ant-menu-item {
    color: hsl(0deg 59% 55% / 65%);
    height: 50px;
    font-size: 1rem;
}

.ant-menu-dark .ant-menu-item>span>a {
    color: hsl(0deg 59% 55% / 65%); 
    padding-bottom: 3px !important;
}

.ant-menu-dark .ant-menu-item>span>a:hover {
    color: hsl(0deg 59% 55% / 65%); 
}

.ant-menu.ant-menu-dark {
    background-color: #fff !important;
    
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: rgb(239, 69, 69) !important;
    border-right: 5px solid black !important;
    border-bottom: 1px solid black;
    padding-top: 5px;
    padding-bottom: 6px;
    
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background-color: #fff !important;
}

.ant-layout-header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(239, 69, 69) !important;
    color: white;
}


header .anticon svg {
    height: 35px;
    width: 25px;
    font-size: 25px;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-menu-inline-collapsed-tooltip svg { display: none; }