.ant-modal-header {
    background-color: rgb(239, 69, 69) !important;
}

.ant-modal-title {
    color: white;
}

.ant-modal-close {
    color: white;
}

.ant-modal-close:hover {
    color: white;
}